






















































































































































































































































import Vue from "vue";
import RaidCreationCard from "@/components/raidmanagement/card/RaidCreationCard.vue";
//import ShiftCreationCard from "@/components/raidmanagement/card/ShiftCreationCard.vue";
import RaidRecurringRuns from "@/components/raidmanagement/card/RaidRecurringRunsCard.vue";
//import ShiftRecurringRuns from "@/components/raidmanagement/card/ShiftRecurringRunsCard.vue";
import RaidScheduleCard from "@/components/raidmanagement/card/RaidScheduleCard.vue";
//import ShiftScheduleCard from "@/components/raidmanagement/card/ShiftScheduleCard.vue";
import GoldOverviewCard from "@/components/raidmanagement/card/GoldOverviewCard.vue";
//import ShiftGoldOverviewCard from "@/components/raidmanagement/card/ShiftGoldOverviewCard.vue";
import AdvBookingOverviewCard from "@/components/raidmanagement/card/AdvBookingOverviewCard.vue";
//import ShiftAdvBookingOverviewCard from "@/components/raidmanagement/card/ShiftAdvBookingOverviewCard.vue";
import CompletedCancelledRunOverviewCard from "@/components/raidmanagement/card/CompletedCancelledRunOverviewCard.vue";
import LogsCard from "@/components/raidmanagement/card/LogsCard.vue";
//import ShiftCompletedCancelledRunOverviewCard from "@/components/raidmanagement/card/ShiftCompletedCancelledRunOverviewCard.vue";

export default Vue.extend({
  name: "RaidManagement",
  components: {
    LogsCard,
    CompletedCancelledRunOverviewCard,
    //ShiftCompletedCancelledRunOverviewCard,
    //ShiftGoldOverviewCard,
    GoldOverviewCard,
    AdvBookingOverviewCard,
    //ShiftAdvBookingOverviewCard,
    RaidScheduleCard,
    //ShiftScheduleCard,
    //ShiftCreationCard,
    RaidCreationCard,
    RaidRecurringRuns,
    //ShiftRecurringRuns
  },
});
