
















import Vue from "vue";
import MountBookingAndHistoryForm from "@/components/booking/form/MountBookingAndHistoryForm.vue";

export default Vue.extend({
  name: "MountBookingAndHistory",
  components: { MountBookingAndHistoryForm },
});
