
































































import Vue from "vue";
import MountRaidManagementButtons from "@/components/raidmanagement/MountRaidManagementButtons.vue";
import moment from "moment-timezone";
import {
  Day,
  GoldCollector,
  Leader,
  Raid,
  RaidDifficulties,
} from "@/interfaces/Raids";

export default Vue.extend({
  name: "MountRaidScheduleItem",
  components: { MountRaidManagementButtons },
  data: () => ({}),
  methods: {
    editRaid(raid: Raid, field: string, event: any) {
      const raidData = {
        ...raid,
        [field]: typeof event === "object" ? event.target.value : event,
      };
      this.$store.dispatch("updateMountRaid", raidData);
    },
    clearValue(raid: Raid, field: string) {
      const raidData = {
        ...raid,
        [field]: "",
      };
      this.$store.dispatch("updateMountRaid", raidData);
    },
    formatDate(date: string, format: string): string {
      return moment(date).format(format);
    },
    isLocked() {
      return !this.userRoles.some(
        (role) =>
          role === "RAIDMANAGER" ||
          role === "GCMANAGER" ||
          role === "ADMIN" ||
          role === "GOLDCOLLECTOR" ||
          role === "RAIDLEADER" ||
          role === "PREMIUMRAIDLEADER"
      );
    },
  },
  computed: {
    userRoles: function (): Array<string> {
      return this.$store.state.roles;
    },
    overviewRole: function (): string {
      const isManager = this.userRoles.some((role) => role === "RAIDMANAGER");
      if (isManager) {
        return "manager";
      }
      return "leaderandgc";
    },
  },
  props: {
    raid: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    goldCollectors: {
      type: Array,
      required: true,
    },
    leaders: {
      type: Array,
      required: true,
    },
  },
});
