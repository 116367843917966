
























































































































































































import Vue from "vue";
import RaidOverviewCard from "@/components/booking/card/RaidOverviewCard.vue";
import MountRaidOverviewCard from "@/components/booking/card/MountRaidOverviewCard.vue";
// import ShiftOverviewCard from "@/components/booking/card/ShiftOverviewCard.vue";
import BookingAndHistoryCard from "@/components/booking/card/BookingAndHistoryCard.vue";
import MountBookingAndHistoryCard from "@/components/booking/card/MountBookingAndHistoryCard.vue";
import ShiftBookingAndHistoryCard from "@/components/booking/card/ShiftBookingAndHistoryCard.vue";
import StringHistoryCard from "@/components/booking/card/StringHistoryCard.vue";
import ManagerCard from "@/components/booking/card/ManagerCard.vue";
//import ManagerShiftCard from "@/components/booking/card/ManagerShiftCard.vue";
import CycleHistoryCard from "@/components/booking/card/CycleHistoryCard.vue";
import YourRunsCard from "@/components/booking/card/YourRunsCard.vue";
import YourMountRunsCard from "@/components/booking/card/YourMountRunsCard.vue";
import YourRunHistoryOverviewCard from "@/components/booking/card/YourRunHistoryOverviewCard.vue";
//import ShiftHistoryCard from "@/components/booking/card/ShiftHistoryCard.vue";

export default Vue.extend({
  name: "RaidOverviewEntry",
  components: {
    //ShiftHistoryCard,
    YourRunsCard,
    YourMountRunsCard,
    RaidOverviewCard,
    MountRaidOverviewCard,
    //ShiftOverviewCard,
    BookingAndHistoryCard,
    MountBookingAndHistoryCard,
    ShiftBookingAndHistoryCard,
    StringHistoryCard,
    ManagerCard,
    //ManagerShiftCard,
    CycleHistoryCard,
    YourRunHistoryOverviewCard,
  },
});
