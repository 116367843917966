













import Vue from "vue";
import MountRaidEditForm from "@/components/raidmanagement/form/MountRaidEditForm.vue";

export default Vue.extend({
  name: "MountRaidEdit",
  components: { MountRaidEditForm },
});
