













import MountRaidOverviewForm from "@/components/booking/form/MountRaidOverviewForm.vue";
import Vue from "vue";

export default Vue.extend({
  name: "MountRaidOverview",
  components: { MountRaidOverviewForm },
});
