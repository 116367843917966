



















































































































































































































































import {
  difficultyOptions,
  raidOptions,
  raidTypeOptions,
  realmOptions,
  typeOptions,
  raidBossOptions,
} from "@/shared/constants";
import Vue from "vue";
import { SnackbarData } from "@/interfaces/SnackbarData";
import {
  GoldCollector,
  Leader,
  LootType,
  Raid,
  RaidDifficulties,
  RaidOption,
  RaidType,
} from "@/interfaces/Raids";
import moment from "moment/moment";
import router from "@/router";

export default Vue.extend({
  name: "RaidEditForm",
  data: () => ({
    valid: false,
    note: "",
    date: "",
    time: "",
    type: "" as LootType,
    raidOptions: raidOptions,
    typeOptions: typeOptions,
    raidBoss: "",
    raidBossOptions: raidBossOptions,
    gc: "",
    leader: "",
    gcDoesInvites: false,
    goldCollectors: [] as Array<GoldCollector>,
    leaders: [] as Array<Leader>,
    possibleCustomers: 0,
    difficulty: difficultyOptions[1],
    difficultyOptions: difficultyOptions,
    raidType: raidTypeOptions[0] as RaidType,
    raidTypeOptions: raidTypeOptions,
    loading: false,
    snackbar: false,
    snackbarText: "Should not show",
    snackbarColor: "red",
    timeout: 2000,
    raid: null,
    raidOption: "" as RaidOption,
    runs: [] as Array<Raid>,
    textRules: [
      (v: string) => !!v || "Field is required",
      (v: string) =>
        (!!v && v.replace(/\s/g, "").length > 1) ||
        "Name must be at least 1 non-whitespace character",
    ],
    paymentRealmRule: [
      (v: any) => !!v || "Field is required",
      (v: any) => (!!v && realmOptions.includes(v)) || "Unknown Realm selected",
    ],
    required: [(v: any) => !!v || "Field is required"],
    arrayRequired: [
      (v: any) => !!v || "Field is required",
      (v: any) => (!!v && v.length > 0) || "Field is required",
    ],
    dateRules: [(v) => !!v || "Date is required"],
  }),
  methods: {
    getFactionIcon(item: string) {
      if (item === "Horde") {
        return require("@/assets/Logo/horde_small.png");
      } else if (item === "Alliance") {
        return require("@/assets/Logo/alliance_small.png");
      }
    },
    resetForm() {
      const form: any = this.$refs.form;
      form.reset();
    },
    async editRaid() {
      const form: any = this.$refs.form;
      this.loading = true;
      form.validate();
      const raidData = this.raid || {};
      const raid: Raid = {
        ...raidData,
        date: this.date,
        time: this.time,
        raid: this.raidOption,
        type: this.type,
        raidType: this.raidType,
        raidBoss: this.raidBoss,
        difficulty: this.difficulty as RaidDifficulties,
        leader: this.leader,
        gc: this.gc,
        gcDoesInvites: this.gcDoesInvites,
        amountOfPossibleCustomer: this.possibleCustomers,
        note: this.note,
      } as Raid;

      const response = await this.$store.dispatch("updateMountRaid", raid);
      if (response.status >= 200 && response.status < 400) {
        this.snackbarToggle({
          message: "Raid sucessfully updated!",
          color: "green",
        });
        Vue.nextTick(() => {
          router.push({ path: "/booking/mountraid/overview" });
        });
      } else {
        this.snackbarToggle({
          message: "Raid update failed! Please try again later.",
          color: "red",
        });
      }
      this.loading = false;
    },
    snackbarToggle(snackbarData: SnackbarData) {
      this.snackbarText = snackbarData.message;
      this.snackbarColor = snackbarData.color;
      this.snackbar = true;
    },
    isManagerOrHigher: function (): boolean {
      return this.$store.state.roles.some(
        (role) =>
          role === "RAIDMANAGER" || role === "WEBACCESS" || role === "ADMIN"
      );
    },
  },
  computed: {},
  mounted: function () {
    this.$nextTick(function () {
      this.$store.dispatch("getGoldCollectors");
      this.$store.dispatch("getLeaders");
      this.$store.dispatch("getMountRaids", {
        noReload: true,
      });
      this.raid = this.$store.getters.getMountRaid(this.$route.params.raidId);
      if (this.raid) {
        const raid = this.raid || ({} as Raid);
        this.date = moment(raid.date).format("Y-MM-DD");
        const time: Array<string> = raid.time.split(":");
        this.time = moment(raid.date)
          .set("hours", parseInt(time[0]))
          .set("minutes", parseInt(time[1]))
          .format("HH:mm");
        this.raidOption = raid.raid;
        this.type = raid.type;
        this.raidType = raid.raidType;
        this.raidBoss = raid.raidBoss;
        this.difficulty = raid.difficulty;
        this.leader = raid.leader;
        this.gc = raid.gc;
        this.gcDoesInvites = raid.gcDoesInvites ? raid.gcDoesInvites : false;
        this.possibleCustomers = raid.amountOfPossibleCustomer;
        this.note = raid.note;
      }
    });
  },
  watch: {
    "$store.state.goldCollectors": {
      deep: true,
      handler: function () {
        this.goldCollectors = this.$store.state.goldCollectors;
      },
    },
    "$store.state.leaders": {
      deep: true,
      handler: function () {
        this.leaders = this.$store.state.leaders;
      },
    },
  },
});
