var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","color":"rgba(0, 0, 0, 0.3)","dark":""}},[_c('router-link',{attrs:{"to":"/#home"}},[_c('v-toolbar-title',{attrs:{"data-cy":"titleBtn"}},[_c('v-img',{staticClass:"shrink mr-2",attrs:{"alt":"Oblivion Logo","contain":"","src":require("@/assets/Partners/oblivion.png"),"transition":"scale-transition","width":"60"}})],1)],1),_c('v-btn',{staticClass:"hidden-sm-and-down nav-menu oblivion-nav-button",attrs:{"text":"","to":"/#services","data-cy":"menuBtn"}},[_vm._v("Oblivion ")]),_c('v-btn',{staticClass:"hidden-sm-and-down nav-menu oblivion-nav-button",attrs:{"text":"","to":"/#about","data-cy":"menuBtn"}},[_vm._v("About ")]),(
      _vm.$store.state.roles.some(
        function (role) { return role === 'WEBADMIN' ||
          role === 'RAIDMANAGER' ||
          role === 'GCMANAGER' ||
          role == 'ADVMANAGER' ||
          role === 'RAIDLEADER' ||
          role === 'PREMIUMRAIDLEADER' ||
          role === 'GOLDCOLLECTOR'; }
      )
    )?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/raid"}},[_c('v-btn',{staticClass:"hidden-sm-and-down nav-menu oblivion-nav-button",attrs:{"text":"","data-cy":"menuBtn"}},[_vm._v("Site Management ")])],1):_vm._e(),(_vm.$store.state.roles.some(function (role) { return role === 'BOOKING'; }))?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/booking"}},[_c('v-btn',{staticClass:"hidden-sm-and-down nav-menu oblivion-nav-button",attrs:{"text":"","data-cy":"menuBtn"}},[_vm._v("Bot Bookings ")])],1):_vm._e(),(
      _vm.$store.state.roles.some(
        function (role) { return role === 'ADVMANAGER' ||
          role === 'RAIDMANAGER' ||
          role === 'ADMIN' ||
          role === 'ADVERTISER' ||
          role === 'MANAGER' ||
          role === 'RAIDLEADER' ||
          role === 'PREMIUMRAIDLEADER'; }
      )
    )?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/booking/raid2"}},[_c('v-btn',{staticClass:"hidden-sm-and-down nav-menu oblivion-nav-button",attrs:{"text":"","data-cy":"menuBtn"}},[_vm._v("Raid bookings ")])],1):_vm._e(),(
      _vm.$store.state.roles.some(
        function (role) { return role === 'RAIDMANAGER' ||
          role === 'RAIDLEADER' ||
          role === 'PREMIUMRAIDLEADER'; }
      ) || _vm.$store.state.user.id === '313259182931312640'
    )?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/raid/roster"}},[_c('v-btn',{staticClass:"hidden-sm-and-down nav-menu oblivion-nav-button",attrs:{"text":"","data-cy":"menuBtn"}},[_vm._v(" Raid Roster ")])],1):_vm._e(),_c('v-spacer'),_c('div',[(!_vm.$store.state.isAuthenticated)?_c('v-btn',{staticClass:"pa-5",attrs:{"color":"#6381b4","href":_vm.login_url}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-discord")]),_c('div',{staticClass:"ml-1 hidden-md-and-down oblivion-nav-button"},[_vm._v("Login")])],1):_vm._e(),(_vm.$store.state.isAuthenticated && _vm.$store.state.user.username)?_c('v-menu',{attrs:{"rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var attrs = ref.attrs;
    var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text pt-1 pb-1",attrs:{"color":"#6381b4","height":"100%"}},'v-btn',attrs,false),on),[_c('v-avatar',[(_vm.$store.state.user.avatar)?_c('v-img',{attrs:{"src":("https://cdn.discordapp.com/avatars/" + (_vm.$store.state.user.id) + "/" + (_vm.$store.state.user.avatar) + (_vm.$store.state.user.avatar.startsWith('a_') ? '.gif' : '.png')),"alt":"John"}}):_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-account-circle")])],1),_c('div',{staticClass:"ml-1 hidden-md-and-down oblivion-nav-button"},[_vm._v(" "+_vm._s(((_vm.$store.state.user.username) + "#" + (_vm.$store.state.user.discriminator)))+" ")])],1)]}}],null,false,2653068588)},[_c('v-list',{staticStyle:{"background":"linear-gradient(#000923, #181819)"}},[(_vm.$store.state.roles.some(function (role) { return role === 'OVERVIEW'; }))?_c('v-list-item',{staticClass:"item",attrs:{"to":"/cycle"}},[_c('v-list-item-title',{staticClass:"oblivion-nav-button white--text",domProps:{"textContent":_vm._s('Balance/Attendance Overview')}})],1):_vm._e(),(_vm.$store.state.roles.some(function (role) { return role === 'BOOKING'; }))?_c('v-list-item',{staticClass:"item",attrs:{"to":"/booking"}},[_c('v-list-item-title',{staticClass:"oblivion-nav-button white--text",domProps:{"textContent":_vm._s('Bot Bookings')}})],1):_vm._e(),(
            _vm.$store.state.roles.some(
              function (role) { return role === 'ADVMANAGER' ||
                role === 'RAIDMANAGER' ||
                role === 'ADMIN' ||
                role === 'ADVERTISER' ||
                role === 'MANAGER' ||
                role === 'RAIDLEADER' ||
                role === 'SHIFTACCESS' ||
                role === 'PREMIUMRAIDLEADER'; }
            )
          )?_c('v-list-item',{staticClass:"item",attrs:{"to":"/booking/raid2"}},[_c('v-list-item-title',{staticClass:"oblivion-nav-button white--text",domProps:{"textContent":_vm._s('Raid Bookings')}})],1):_vm._e(),(
            _vm.$store.state.roles.some(
              function (role) { return role === 'WEBADMIN' ||
                role === 'RAIDMANAGER' ||
                role === 'GCMANAGER' ||
                role === 'ADVMANAGER' ||
                role === 'RAIDLEADER' ||
                role === 'GOLDCOLLECTOR' ||
                role === 'PREMIUMRAIDLEADER'; }
            )
          )?_c('v-list-item',{staticClass:"item",attrs:{"to":"/raid"}},[_c('v-list-item-title',{staticClass:"oblivion-nav-button white--text",domProps:{"textContent":_vm._s('Site Management')}})],1):_vm._e(),(_vm.$store.state.roles.some(function (role) { return role === 'WEBADMIN'; }))?_c('v-list-item',{staticClass:"item",attrs:{"to":"/administration"}},[_c('v-list-item-title',{staticClass:"oblivion-nav-button white--text",domProps:{"textContent":_vm._s('Administration')}})],1):_vm._e(),(_vm.$store.state.roles.length > 0)?_c('v-list-item',{staticClass:"item",attrs:{"link":"","href":"https://faq.oblivion-marketplace.com","target":"_blank"}},[_c('v-list-item-title',{staticClass:"oblivion-nav-button white--text"},[_vm._v("FAQ "),_c('v-icon',{staticClass:"pl-1",attrs:{"color":"white"}},[_vm._v(" mdi-open-in-new ")])],1)],1):_vm._e(),(_vm.$store.state.roles.length === 0)?_c('v-list-item',{staticClass:"item",attrs:{"link":"","href":"https://discord.gg/obc","target":"_blank"}},[_c('v-list-item-title',{staticClass:"oblivion-nav-button white--text"},[_vm._v("APPLY "),_c('v-icon',{staticClass:"pl-1",attrs:{"color":"white"}},[_vm._v(" mdi-open-in-new ")])],1)],1):_vm._e(),_c('v-list-item',{staticClass:"item",attrs:{"link":""},on:{"click":_vm.logout}},[_c('v-list-item-title',{staticClass:"oblivion-nav-button white--text",domProps:{"textContent":_vm._s('Logout')}})],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }