




















import Vue from "vue";
import MountRaidRoleOverviewList from "@/components/raidmanagement/MountRaidRoleOverviewList.vue";

export default Vue.extend({
  name: "MountRaidRoleOverview",
  components: { MountRaidRoleOverviewList },
});
