













































































































































import Vue from "vue";
import { StoreItem, Item } from "@/interfaces/Item";

export default Vue.extend({
  name: "LoyaltyStoreForm",
  components: {},
  data: () => ({
    valid: false,
    items: {} as { [key: string]: Item[] },
    cart: [] as Item[],
  }),
  methods: {
    async loadItems() {
      const response = await this.$store.dispatch("getItems", {});
      if (response.status >= 400) {
        this.$emit("snackbarToggle", {
          message: "No available items found",
          color: "red accent-1",
        });
      }
      this.items = response.data;
    },
    async checkoutCart() {
      const response = await this.$store.dispatch("checkoutCart", {
        items: this.cart,
        totalCosts: this.totalCostsCart(),
      });
      if (response.status == 200) {
        this.$emit("snackbarToggle", {
          message:
            "Checkout successful. You will get informed by our staff team.",
          color: "green accent-1",
        });
        this.cart = [];
      } else {
        this.$emit("snackbarToggle", {
          message: "Checkout failed. Please try again later.",
          color: "red accent-1",
        });
      }
    },
    removeItemFromCart(index: number) {
      this.cart.splice(index, 1);
    },
    addToCart(storeItem: any) {
      const item: Item = {
        id: storeItem.id,
        label: storeItem.label,
        description: storeItem.description,
        url: storeItem.url,
        amount: storeItem.amount,
        category: storeItem.category,
      };
      this.cart.push(item);
    },
    totalCostsCart() {
      let total = 0;
      for (let item of this.cart) {
        total += Number(item.amount);
      }
      return total;
    },
  },
  computed: {
    loyaltyPoints() {
      return this.$store.state.loyaltySystem;
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      this.$store.dispatch("getLoyaltyData");
      this.loadItems();
    });
  },
});
