


























































































































































































































































































































































































































































































































































































import {
  customerGroupStatuses,
  dayOptions,
  difficultyOptions,
  runStateOptions,
  typeOptions,
  WoWClass,
  realmOptions,
} from "@/shared/constants";
import Vue from "vue";
import { SnackbarData } from "@/interfaces/SnackbarData";
import { Customer, Day, Raid, RaidDifficulties } from "@/interfaces/Raids";
import moment from "moment";
import MountRaidBookingForm from "@/components/raidmanagement/form/MountRaidBookingForm.vue";
import RaidBookingMenu from "@/components/booking/RaidBookingMenu.vue";
import MountRaidManagementButtons from "@/components/raidmanagement/MountRaidManagementButtons.vue";

export default Vue.extend({
  name: "MountRaidRoleOverviewList",
  components: {
    MountRaidManagementButtons,
    RaidBookingMenu,
    MountRaidBookingForm,
  },
  methods: {
    copy(val: string) {
      navigator.clipboard.writeText(val);
    },
    totalPot(bookings: Array<Customer>) {
      let sum = 0;
      bookings.forEach((booking: Customer) => {
        if (
          booking.goldWith &&
          booking.paid &&
          booking.customerStatus === "In group" &&
          booking.completed
        ) {
          sum += booking.price;
        }
      });
      return sum;
    },
    getClassIcon(fieldClass: WoWClass) {
      let classWithCapitalStart =
        fieldClass.charAt(0).toUpperCase() + fieldClass.slice(1);
      return require(`@/assets/Icons/${classWithCapitalStart.replaceAll(
        " ",
        "_"
      )}_Class.webp`);
    },
    editRaid(raid: Raid, field: string, event: any) {
      const raidData = {
        ...raid,
        [field]: typeof event === "object" ? event.target.value : event,
      };

      this.$store.dispatch("updateMountRaid", raidData);
      let indexOfRaid = this.runs.findIndex((r: Raid) => r.id === raid.id);
      this.runs[indexOfRaid][field] =
        typeof event === "object" ? event.target.value : event;
    },
    markBookingsCompleted(raid: Raid) {
      if (this.bookingsCompleted) {
        let raidData = {
          ...raid,
        };

        raidData.Buyer?.forEach((element) => {
          if (
            element.paid &&
            element.customerStatus == "In group" &&
            element.goldWith?.length > 0
          ) {
            element.completed = true;
          }
        });

        raidData.Backup?.forEach((element) => {
          if (
            element.paid &&
            element.customerStatus == "In group" &&
            element.goldWith?.length > 0
          ) {
            element.completed = true;
          }
        });

        this.$store.dispatch("updateRaidCustomer", raidData);
      }
    },
    editBooking(raid: Raid, booking: Customer, field: string, event: any) {
      const isGc = this.$store.state.roles.some(
        (role) => role === "GOLDCOLLECTOR"
      );
      const customerData = {
        ...booking,
        [field]: typeof event === "object" ? event.target.value : event,
      };
      if (field === "paid" && isGc && customerData["goldWith"] === "") {
        customerData["goldWith"] = this.$store.state.user.id;
      }
      let place = "Buyer";
      let indexOfRaid = this.runs.findIndex((r: Raid) => r.id === raid.id);
      let indexOfCustomer = raid.Buyer.findIndex(
        (c: Customer) => c.id === booking.id
      );
      if (indexOfCustomer === -1) {
        place = "Backup";
        indexOfCustomer = Object.values(raid.Backup || []).findIndex(
          (c: Customer) => c.id === booking.id
        );
        this.runs[indexOfRaid][place][indexOfCustomer][field] =
          typeof event === "object" ? event.target.value : event;
        if (field === "paid" && isGc && customerData["goldWith"] === "") {
          this.runs[indexOfRaid][place][indexOfCustomer]["goldWith"] =
            this.$store.state.user.id;
        }
      } else {
        this.runs[indexOfRaid][place][indexOfCustomer][field] =
          typeof event === "object" ? event.target.value : event;
        if (field === "paid" && isGc && customerData["goldWith"] === "") {
          this.runs[indexOfRaid][place][indexOfCustomer]["goldWith"] =
            this.$store.state.user.id;
        }
      }

      this.$store.dispatch("updateMountBooking", {
        booking: customerData,
        raid: raid,
      });
    },
    getFactionIcon(item: string) {
      if (item === "Horde") {
        return require("@/assets/Logo/horde_small.png");
      } else if (item === "Alliance") {
        return require("@/assets/Logo/alliance_small.png");
      }
    },
    resetForm() {
      const form: any = this.$refs.form;
      form.reset();
    },
    snackbarToggle(snackbarData: SnackbarData) {
      this.snackbarText = snackbarData.message;
      this.snackbarColor = snackbarData.color;
      this.snackbar = true;
    },
    selectRun(run: Raid) {
      this.selectedRun = run;
    },
    loadRuns: function () {
      this.runs = this.$store.state.mountRaids;
      return this.$store.state.mountRaids;
    },
    copyName(bookings: Array<Customer>) {
      navigator.clipboard.writeText(
        bookings
          .map((b) => {
            if (
              b.customerStatus !== "Cancelled" &&
              b.customerStatus !== "Duplicate"
            ) {
              return b.buyer;
            }
          })
          .join("\r\n")
      );
      this.snackbarToggle({
        message: "Customer name copied!",
        color: "green",
      });
    },
    getAllBooking(raid: Raid): Array<Customer> {
      let backups: Array<Customer> = (raid.Backup || []).map((c: Customer) => {
        c.backup = true;
        return c;
      }) as Array<Customer>;

      return [...raid.Buyer, ...backups].map((c: Customer) => {
        c.raid = raid.id;
        return c;
      });
    },
    setEditDialog(raidId: string, bookingId: string) {
      this.editDialog = true;

      this.raidToEdit = raidId;
      this.bookingToEdit = bookingId;
    },
    fireEvent() {
      if (this.deleteConfirmAction) {
        this[this.deleteConfirmAction]();
      }
    },
    openDeleteConfirmationDialog(booking: Customer) {
      this.deleteConfirmDialog = true;
      this.deleteConfirmCustomer = booking;
      this.deleteConfirmAction = "deleteBooking";
    },
    async deleteBooking() {
      const booking: Customer = this.deleteConfirmCustomer as Customer;
      const found = this.runs.find((raid: Raid) => raid.id === booking.raid);
      if (found) {
        const raid: Raid = found as Raid;
        if (raid && booking) {
          const response = await this.$store.dispatch("updateMountBooking", {
            booking: { ...booking, delete: true },
            raid: raid,
          });
          if (response.status >= 200 && response.status < 400) {
            this.snackbarToggle({
              message: "Your booking has been successfully cancelled.",
              color: "green",
            });
            this.deleteConfirmDialog = false;
          } else {
            this.snackbarToggle({
              message: "Your booking cancel failed! Please try again later.",
              color: "red",
            });
          }
        }
      }
    },
    fireBaitEvent() {
      if (this.baitConfirmAction) {
        this[this.baitConfirmAction]();
      }
    },
    openBaitConfirmationDialog(raid: Raid) {
      this.baitConfirmDialog = true;
      this.baitConfirmRaid = raid;
      this.baitConfirmAction = "createBaitBookings";
    },
    goBack(e) {
      e.preventDefault();
      this.$router.go(-1);
    },
    toggleRaid(raid: Raid) {
      if (this.hiddenRaids.includes(raid.id)) {
        const removeArray = this.hiddenRaids;
        removeArray.splice(removeArray.indexOf(raid.id), 1);
        localStorage.setItem("hiddenMountRaids", JSON.stringify(removeArray));
        this.hiddenRaidsData = removeArray;
      } else {
        const addArray = this.hiddenRaids;
        addArray.push(raid.id);
        localStorage.setItem("hiddenMountRaids", JSON.stringify(addArray));
        this.hiddenRaidsData = addArray;
      }
    },
    activeBuyerCount(raid: Raid) {
      let sum = 0;
      this.getAllBooking(raid).forEach((c: Customer) => {
        if (
          c.customerStatus !== "Cancelled" &&
          c.customerStatus !== "Backup" &&
          c.customerStatus !== "AFK" &&
          c.customerStatus !== "Offline" &&
          c.customerStatus !== "Duplicate"
        ) {
          sum += 1;
        }
      });
      return sum;
    },
    inGroupCount(raid: Raid) {
      let sum = 0;
      this.getAllBooking(raid).forEach((c: Customer) => {
        if (c.customerStatus === "In group") {
          sum += 1;
        }
      });
      return sum;
    },
    isManagerOrGc: function (row): boolean {
      return (
        this.$store.state.roles.some(
          (role) =>
            role === "RAIDMANAGER" || role === "WEBACCESS" || role === "ADMIN"
        ) || row.gc == this.$store.state.user.id
      );
    },
    isManagerOrLeader: function (row): boolean {
      return (
        this.$store.state.roles.some(
          (role) =>
            role === "RAIDMANAGER" || role === "WEBACCESS" || role === "ADMIN"
        ) || row.leader == this.$store.state.user.id
      );
    },
    isManagerOrHigher: function (): boolean {
      return this.$store.state.roles.some(
        (role) =>
          role === "RAIDMANAGER" || role === "WEBACCESS" || role === "ADMIN"
      );
    },
  },
  data: () => ({
    runs: [] as Array<Raid>,
    valid: false,
    selectedRun: {} as Raid,
    loading: false,
    snackbar: false,
    typeOptions: typeOptions,
    difficulties: difficultyOptions,
    customerGroupStatuses: customerGroupStatuses,
    paymentRealmOptions: realmOptions,
    gcPaymentRealm: "",
    dayOptions: dayOptions,
    snackbarText: "Should not show",
    snackbarColor: "red",
    timeout: 2000,
    amountOfBookings: 0,
    raidTypeFilter: null,
    editDialog: false,
    raidId: "",
    deleteConfirmDialog: false,
    deleteConfirmCustomer: {},
    deleteConfirmRaid: "",
    deleteConfirmAction: "",
    baitConfirmDialog: false,
    baitConfirmRaid: {},
    baitConfirmAction: "",
    bookingToEdit: "",
    raidToEdit: "",
    difficultyFilter: [] as Array<RaidDifficulties>,
    dayFilter: [] as Array<Day>,
    availabilityFilter: false,
    prevRoute: "",
    hiddenRaidsData: [] as Array<string>,
    runStateOptions: runStateOptions,
    bookingsCompleted: false,
    externalIconUrl: "https://i.imgur.com/qDAnprt.png",
    paymentRealmRule: [
      (v: any) => !!v || "Field is required",
      (v: any) =>
        (!!v &&
          realmOptions
            .map((element: string) => element.toLowerCase())
            .includes(v.toLowerCase())) ||
        "Unknown Realm selected",
    ],
  }),
  computed: {
    moment() {
      return moment;
    },
    isManager: function (): boolean {
      return this.$store.state.roles.some(
        (role) =>
          role === "RAIDMANAGER" ||
          role === "SUPERADMIN" ||
          role === "WEBACCESS"
      );
    },
    hiddenRaids(): Array<string> {
      return this.hiddenRaidsData;
    },
    filteredRaids: function () {
      const raids: Array<Raid> = [];
      if (this.raidId) {
        let raid = this.runs.filter((raid: Raid) => raid.id === this.raidId);
        if (
          raid.length === 1 &&
          (raid[0].leader == this.$store.state.user.id ||
            raid[0].gc == this.$store.state.user.id ||
            this.$store.state.roles.some(
              (role) =>
                role === "RAIDMANAGER" ||
                role === "SUPERADMIN" ||
                role === "ADMIN" ||
                role === "WEBACCESS"
            ))
        ) {
          return this.runs.filter((raid: Raid) => raid.id === this.raidId);
        } else {
          return raids;
        }
      } else {
        if (this.runs) {
          this.loadRuns().forEach((raid: Raid) => {
            if (this.$route.params.type === "leaderandgc") {
              if (
                (raid.leader === this.$store.state.user.id ||
                  raid.gc === this.$store.state.user.id) &&
                raid.runStatus === "active" &&
                !raid.completed
              ) {
                raids.push(raid);
              }
            }
            if (
              this.$route.params.type === "manager" &&
              !raid.completed &&
              raid.runStatus === "active"
            ) {
              raids.push(raid);
            }
          });
          return raids.sort((a, b) => {
            const dateA = moment(a.date, "YYYY-MM-DD");
            const dateB = moment(b.date, "YYYY-MM-DD");
            const timeA = moment(a.time, "HH:mm");
            const timeB = moment(b.time, "HH:mm");

            if (dateA.isBefore(dateB)) {
              return -1;
            } else if (dateA.isAfter(dateB)) {
              return 1;
            } else {
              return timeA.diff(timeB, "milliseconds");
            }
          });
        }
        return [];
      }
    },
    typeTitle: function () {
      if (this.$route.params.type === "manager") {
        return "All runs";
      }
      return "Your runs";
    },
  },
  mounted: function () {
    this.$store.dispatch("getGoldCollectors");
    this.$store.dispatch("getLeaders");
    this.$store.dispatch("getMountRaids", {
      start: moment()
        .tz("Europe/Berlin")
        .subtract(1, "days")
        .format("YYYY-MM-DD"),
      end: moment()
        .tz("Europe/Berlin")
        .endOf("day")
        .add(6, "hour")
        .format("YYYY-MM-DD"),
      removeFilter: ["runStatus"],
      specificOnly: this.$route.params.raidId,
    });
    this.hiddenRaidsData = JSON.parse(
      localStorage.getItem("hiddenMountRaids") || "[]"
    );
    this.$nextTick(function () {
      const raidLoad = setInterval(() => {
        if (this.$route.params.raidId) {
          this.raidId = this.$route.params.raidId;
          clearInterval(raidLoad);
        }
      }, 500);
    });
  },
  watch: {
    "$store.state.mountRaids": {
      deep: true,
      handler: function () {
        this.loadRuns();
      },
    },
  },
});
