













import Vue from "vue";
import MountRaidCreationForm from "@/components/raidmanagement/form/MountRaidCreationForm.vue";

export default Vue.extend({
  name: "MountRaidCreation",
  components: { MountRaidCreationForm },
});
