













import MountRaidScheduleForm from "@/components/raidmanagement/form/MountRaidScheduleForm.vue";
import Vue from "vue";

export default Vue.extend({
  name: "MountRaidSchedule",
  components: { MountRaidScheduleForm },
});
