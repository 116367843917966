



























































































































































































































































































































































import {
  amountOfKeysOptions,
  armorStackOptions,
  dungeonNameToShortName,
  dungeonOptions,
  factions,
  booleanOptions,
  keystoneLevelOptions,
  realmOptions,
  websiteAdvCutOptions,
  dungeonAbrevations,
} from "@/shared/constants";
import Vue from "vue";
import GoldTextField from "@/components/custom/gold-text-field";
import { SnackbarData } from "@/interfaces/SnackbarData";
import { formatPotToGold } from "@/shared/formatPotToGold";
import SummaryOverlay from "@/components/booking/form/custom/SummaryOverlay.vue";

export default Vue.extend({
  name: "MythicPlusForm",
  components: { SummaryOverlay, GoldTextField },
  data: () => ({
    valid: false,
    faction: "Horde",
    factions: factions,
    advCut: "",
    advCutOptions: websiteAdvCutOptions,
    payFaction: null,
    pot: "",
    buyer: "",
    paymentRealm: "",
    paymentRealmOptions: realmOptions,
    paymentFaction: "",
    requestedDungeons: [
      {
        amountOfKeys: amountOfKeysOptions[0],
        amountOfKeysOptions: amountOfKeysOptions,
        keystoneLevel: "15",
        keystoneLevelOptions: keystoneLevelOptions,
        dungeon: "",
        dungeonOptions: dungeonOptions,
        ref: "dungeon-1",
      },
    ],
    armorStack: armorStackOptions[0],
    armorStackOptions: armorStackOptions,
    timed: booleanOptions[0],
    booleanOptions: booleanOptions,
    buyerPlays: booleanOptions[0],
    note: "",
    btag: "",
    loading: false,
    snackbar: false,
    snackbarText: "Should not show",
    snackbarColor: "red",
    timeout: 2000,
    textRules: [
      (v: string) => !!v || "Field is required",
      (v: string) =>
        (!!v && v.replace(/\s/g, "").length > 1) ||
        "Name must be at least 1 non-whitespace character",
    ],
    required: [(v: any) => !!v || "Field is required"],
    paymentRealmRule: [
      (v: any) => !!v || "Field is required",
      (v: any) => (!!v && realmOptions.includes(v)) || "Unknown Realm selected",
    ],
    dungeonRule: [
      (v: any) => !!v || "Field is required",
      (v: any) =>
        (!!v && dungeonOptions.includes(v)) || "Unknown Dungeon selected",
    ],
  }),
  methods: {
    setPaymentRealm(e) {
      const value = e.target.value;
      if (value.includes("-")) {
        this.paymentRealm = value.split("-")[1];
      }
    },
    getFactionIcon(item: string) {
      if (item === "Horde") {
        return require("@/assets/Logo/horde_small.png");
      } else if (item === "Alliance") {
        return require("@/assets/Logo/alliance_small.png");
      }
    },
    resetForm: function () {
      const form: any = this.$refs.form;
      form.reset();
    },
    snackbarToggle(snackbarData: SnackbarData): void {
      this.snackbarText = snackbarData.message;
      this.snackbarColor = snackbarData.color;
      this.snackbar = true;
    },
    async bookCustomer(shouldBook: boolean) {
      const form: any = this.$refs.form;
      if (shouldBook) {
        this.loading = true;
        form.validate();
        let channel = "";
        const amountOfKeys = this.requestedDungeons.reduce(
          (acc: number, dungeon) => {
            acc += parseInt(dungeon.amountOfKeys);
            return acc;
          },
          0
        );
        const highestKeystoneLevel = this.requestedDungeons.reduce(
          (acc: number, dungeon) => {
            const keyLevel = parseInt(dungeon.keystoneLevel);
            if (keyLevel > acc) {
              acc = keyLevel;
            }
            return acc;
          },
          0
        );

        if (highestKeystoneLevel <= 19 && highestKeystoneLevel >= 16) {
          channel = "High";
        } else if (highestKeystoneLevel <= 23 && highestKeystoneLevel >= 20) {
          channel = "Allstar";
        } else if (highestKeystoneLevel >= 24) {
          channel = "Elite";
        } else {
          channel = "Low";
        }
        let section = "mplus";
        let title = this.requestedDungeons.reduce((acc: string, dungeon) => {
          if (dungeon.amountOfKeys == "8" && dungeon.keystoneLevel == "20") {
            if (acc != "") {
              acc += ", 1x Keystonehero (8x 20)";
            } else {
              acc += "1x Keystonehero (8x 20)";
            }
          } else if (dungeon.dungeon == "M+0 World Tour") {
            if (acc != "") {
              acc += ", M+0 Worldtour";
            } else {
              acc += "M+0 Worldtour";
            }
          } else if (dungeon.dungeon == "M+0 Half World Tour") {
            if (acc != "") {
              acc += ", M+0 Half Worldtour";
            } else {
              acc += "M+0 Half Worldtour";
            }
          } else {
            const shortName = dungeonNameToShortName[dungeon.dungeon];
            if (shortName) {
              if (acc != "") {
                acc += `, ${dungeon.amountOfKeys}x ${shortName} Mythic +${dungeon.keystoneLevel}`;
              } else {
                acc += `${dungeon.amountOfKeys}x ${shortName} Mythic +${dungeon.keystoneLevel}`;
              }
            } else {
              if (acc != "") {
                acc += `, ${dungeon.amountOfKeys}x ${dungeon.dungeon} Mythic +${dungeon.keystoneLevel}`;
              } else {
                acc += `${dungeon.amountOfKeys}x ${dungeon.dungeon} Mythic +${dungeon.keystoneLevel}`;
              }
            }
          }
          return acc;
        }, "");
        title += this.armorStack !== "No" ? " Stack" : "";
        const specificDungeons = this.requestedDungeons.reduce(
          (acc: string[], dungeon) => {
            acc.push(dungeon.dungeon);
            return acc;
          },
          []
        );
        const response = await this.$store.dispatch("createBooking", {
          section: section,
          faction: this.faction,
          pot: parseInt(this.pot).toString(),
          buyer: this.buyer,
          payrealm: this.paymentRealm,
          payfaction: this.paymentFaction,
          advcut: this.advCut,
          gc: "BOOSTER",
          buyerplays: this.buyerPlays,
          timed: this.timed,
          note: this.note,
          btag: this.btag,
          stack: this.armorStack,
          channel: channel,
          title: title,
          specific: specificDungeons.toString().replace(",", ", "),
          highestKeystoneLevel: highestKeystoneLevel,
        });
        if (response.status >= 200 && response.status < 400) {
          this.snackbarToggle({
            message: "Your booking has been successfully created.",
            color: "green",
          });
          // form.reset();
        } else {
          this.snackbarToggle({
            message: "Your booking failed! Please try again later.",
            color: "red",
          });
        }
        this.loading = false;
      }
    },
    removeKey(index: number): void {
      this.requestedDungeons.splice(index, 1);
    },
    addKey: function () {
      this.requestedDungeons.push({
        amountOfKeys: amountOfKeysOptions[0],
        amountOfKeysOptions: amountOfKeysOptions,
        keystoneLevel: "15",
        keystoneLevelOptions: keystoneLevelOptions,
        dungeon: "",
        dungeonOptions: dungeonOptions,
        ref: `dungeon-${this.requestedDungeons.length + 1}`,
      });
      Vue.nextTick(() => {
        (this.$refs[`dungeon-${this.requestedDungeons.length}`] || [])[0][
          "focus"
        ]();
      });
    },
  },
  computed: {
    computeSummaryValues: function (): Array<{ label: string; value: any }> {
      const values: any = [];
      values.push({ label: "Faction", value: this.faction });
      values.push({ label: "Customer", value: this.buyer });
      values.push({ label: "FeeOption", value: this.advCut });
      values.push({
        label: "Price",
        value: formatPotToGold((parseFloat(this.pot) * 1000).toString()),
      });
      values.push({ label: "Payment Realm", value: this.paymentRealm });
      values.push({ label: "Payment Faction", value: this.paymentFaction });
      this.requestedDungeons.forEach((requestedDungeon, index) => {
        const prefix = index + 1 + ": ";
        values.push({
          label: `${prefix}Dungeon`,
          value: requestedDungeon.dungeon,
        });
        values.push({
          label: `${prefix}Keystone Level`,
          value: requestedDungeon.keystoneLevel,
        });
        values.push({
          label: `${prefix}#Keys`,
          value: requestedDungeon.amountOfKeys,
        });
      });
      values.push({ label: "Armor Stack", value: this.armorStack });
      values.push({ label: "Timed?", value: this.timed });
      values.push({ label: "Plays?", value: this.buyerPlays });
      return values;
    },
    keystoneSum: function (): string {
      return this.requestedDungeons
        .map((dungeon: any) => {
          if (dungeon.dungeon) console.log(dungeon.dungeon);
          return `${dungeon.amountOfKeys}x ${dungeon.keystoneLevel} ${
            dungeonAbrevations[dungeon.dungeon]
          }`;
        })
        .join(", ");
    },
  },
  // mounted: function() {
  //     this.$nextTick(function() {
  //         this.advCut = this.$store.state.roles.some((role) => role === "TAKENBOOKER") ? "" : memberCutOptions[0];
  //         this.advCutOptions = this.$store.state.roles.some((role) => role === "TAKENBOOKER") ? mplusAdvCutOptions : memberCutOptions;
  //     });
  // },
  watch: {
    advCut: function () {
      if (this.advCut) {
        if (this.advCut.toUpperCase() === "BALANCE") {
          this.paymentRealm = "BALANCE";
          this.paymentRealmOptions = [];
        } else {
          this.paymentRealmOptions = realmOptions;
        }
      }
    },
    paymentRealm: function () {
      if (this.paymentRealm) {
        let realm = "";
        for (let i = 0; i < this.paymentRealm.length; i++) {
          realm += this.paymentRealm[i];
          let found = realmOptions.filter((pr) => {
            const paymentRealm = pr.toLowerCase().replaceAll("'", "");
            const input = realm.toLowerCase().replaceAll("'", "");
            return paymentRealm.includes(input);
          });
          if (found.length === 1) {
            i = this.paymentRealm.length + 1;
            this.paymentRealm = found[0];
          }
        }

        if (this.paymentRealm.toUpperCase() === "BALANCE") {
          this.advCut = "BALANCE";
          this.advCutOptions = [];
        } else {
          this.advCutOptions = websiteAdvCutOptions;
        }
      }
    },
    faction: function (val) {
      if (val) {
        let paymentFaction = "";
        for (let i = 0; i < val.length; i++) {
          paymentFaction += val[i];
          let found = factions.filter((co) => {
            const faction = co.toLowerCase().replaceAll("'", "");
            const input = paymentFaction.toLowerCase().replaceAll("'", "");
            return faction.includes(input);
          });
          if (found.length === 1) {
            i = this.faction.length + 1;
            this.faction = found[0];
          }
        }
      }
    },
    armorStack: function (val) {
      if (val) {
        let paymentFaction = "";
        for (let i = 0; i < val.length; i++) {
          paymentFaction += val[i];
          let found = armorStackOptions.filter((co) => {
            const faction = co.toLowerCase().replaceAll("'", "");
            const input = paymentFaction.toLowerCase().replaceAll("'", "");
            return faction.includes(input);
          });
          if (found.length === 1) {
            i = this.armorStack.length + 1;
            this.armorStack = found[0];
          }
        }
      }
    },
    buyerPlays: function (val) {
      if (val) {
        let paymentFaction = "";
        for (let i = 0; i < val.length; i++) {
          paymentFaction += val[i];
          let found = booleanOptions.filter((co) => {
            const faction = co.toLowerCase().replaceAll("'", "");
            const input = paymentFaction.toLowerCase().replaceAll("'", "");
            return faction.includes(input);
          });
          if (found.length === 1) {
            i = this.buyerPlays.length + 1;
            this.buyerPlays = found[0];
          }
        }
      }
    },
    paymentFaction: function (val) {
      if (val) {
        let paymentFaction = "";
        for (let i = 0; i < val.length; i++) {
          paymentFaction += val[i];
          let found = factions.filter((co) => {
            const faction = co.toLowerCase().replaceAll("'", "");
            const input = paymentFaction.toLowerCase().replaceAll("'", "");
            return faction.includes(input);
          });
          if (found.length === 1) {
            i = this.paymentFaction.length + 1;
            this.paymentFaction = found[0];
          }
        }
      }
    },
    requestedDungeons: {
      handler: function (val) {
        val.forEach((current: any) => {
          if (current.dungeon) {
            let dungeon = "";
            for (let i = 0; i < current.dungeon.length; i++) {
              dungeon += current.dungeon[i];
              let exactAbr = false;
              let found = dungeonOptions.filter((co) => {
                const d = co.toLowerCase().replaceAll("'", "");
                const input = dungeon.toLowerCase().replaceAll("'", "");
                if (dungeonAbrevations[co].toLowerCase() === input) {
                  exactAbr = true;
                }
                return (
                  d.includes(input) ||
                  dungeonAbrevations[co].toLowerCase() === input
                );
              });
              if (found.length === 1 || exactAbr) {
                i = current.dungeon.length + 1;
                current.dungeon = found[0];
                exactAbr = false;
              }
            }
          }
        });
      },
      deep: true,
    },
  },
});
